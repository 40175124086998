export const ROLES = {
  SUPERADMIN: "superadmin",
  ADMIN: "admin",
  MANAGER: "manager",
  TRAINER: "trainer",
  USER: "user",
};
export const ROLE_PERMISSIONS = {
  [ROLES.SUPERADMIN]: {
    canManageAllBusinesses: true,
    canCreateBusiness: true,
    canManageRoles: true,
    canManageLevels: true,
    canManageEnvironments: true,
    canManageSubscriptions: true,
    canCreatePrograms: true,
    canDeletePrograms: true,
    canManageAllUsers: true,
    canCreateLevels: true,
    canViewDashboard: true,
    // canViewPrograms: true,
    canViewAllUsers: true,
    canCreateUsers: true,
    canAssignBusinesses: true,
    canManageActivities: true,
    canManageRoles: true,
    canViewAnyBusiness: true,
    canManagePlaylists: true,
    canManageNewsletters: true
  },
  [ROLES.ADMIN]: {
    canViewDashboard: true,
    canManagePlaylists: true,
    canViewPrograms: true,
    canViewActivities: true,
    canManageOwnBusiness: true,
    canManageBusinessUsers: true,
    canViewBusinessUsers: true,
    canCreateBusinessUsers: true,

    canManageRoles: true,
    canManageNewsletters: true
  },
  [ROLES.MANAGER]: {
    canManageOwnBusiness: true,
    canViewUsers: true,
    canCreatePlaylists: true,
    canManageActivities: true,
    canViewDashboard: true,
    canViewPrograms: true
  },
  [ROLES.TRAINER]: {
    canManageActivities: true,
    canCreatePlaylists: true,
    canViewDashboard: true,
    canViewPrograms: true
  }
}