import { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/lopsteknikk/lopsteknikk.css';

export default function LopsteknikkList() {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [emailContent, setEmailContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/join/lopsteknikk-users`);
            setUsers(response.data.users);
        } catch (error) {
            setError('Failed to fetch users');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
        setError('');
        setSuccessMessage('');
        setEmailContent(`Hi ${user.name},\n\nHere's my feedback on your running technique:\n\n`);
    };

    const handleSendEmail = async () => {
        setIsSending(true);
        setError('');
        setSuccessMessage('');
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/join/send-feedback`, {
                userId: selectedUser.idlopstehnique,
                email: selectedUser.email,
                content: emailContent,
                videoUrls: selectedUser.signedVideoUrls
            });
            
            const updatedUsers = users.map(user => {
                if (user.idlopstehnique === selectedUser.idlopstehnique) {
                    return {
                        ...user,
                        feedbackSent: true,
                        feedbackMessage: emailContent
                    };
                }
                return user;
            });
            
            setUsers(updatedUsers);
            setSuccessMessage('Feedback sent successfully!');
            setTimeout(() => {
                setSelectedUser(null);
                setEmailContent('');
                setSuccessMessage('');
            }, 2000);
        } catch (error) {
            setError('Failed to send feedback');
            console.error('Error:', error);
        } finally {
            setIsSending(false);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="lopsteknikk-container">
            <h2>Løpsteknikk Evaluations</h2>
            
            {error && <div className="error-message">{error}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}
            
            <div className="user-cards">
                {users.map(user => (
                    <div 
                        key={user.idlopstehnique} 
                        className={`user-card ${selectedUser?.idlopstehnique === user.idlopstehnique ? 'selected' : ''} ${user.feedbackSent ? 'feedback-sent' : ''}`}
                        onClick={() => handleUserSelect(user)}
                    >
                        <h3>{user.name}</h3>
                        <p>{user.email}</p>
                        <p>Submitted: {new Date(user.createdAt).toLocaleDateString()}</p>
                        {user.feedbackSent && (
                            <div className="feedback-status">
                                <i className="fas fa-check-circle"></i>
                                <span>Feedback sent</span>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {selectedUser && (
                <div className="user-detail">
                    <h3>User Details</h3>
                    <div className="user-info">
                        <p><strong>Id:</strong> {selectedUser.idlopstehnique}</p>
                        <p><strong>Name:</strong> {selectedUser.name}</p>
                        <p><strong>Email:</strong> {selectedUser.email}</p>
                        <p><strong>Phone:</strong> {selectedUser.phoneNumber}</p>
                        <p><strong>Injury History:</strong> {selectedUser.injuiryHistory}</p>
                        <p><strong>Goals:</strong> {selectedUser.goals}</p>
                        <p><strong>Running Experience:</strong> {selectedUser.runningExperience}</p>
                    </div>

                    <div className="video-section">
                        <h4>Videos</h4>
                        <div className="video-container">
                            {selectedUser.signedVideoUrls?.map((url, index) => (
                                <video 
                                    key={index}
                                    controls
                                    className="technique-video"
                                >
                                    <source src={url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ))}
                        </div>
                    </div>

                    {selectedUser.feedbackSent ? (
                        <div className="feedback-history">
                            <h4>Sent Feedback</h4>
                            <div className="feedback-message">
                                {selectedUser.feedbackMessage}
                            </div>
                            <div className="feedback-date">
                                Sent on: {new Date(selectedUser.feedbackDate).toLocaleString()}
                            </div>
                        </div>
                    ) : (
                        <div className="feedback-section">
                            <h4>Send Feedback</h4>
                            <textarea
                                value={emailContent}
                                onChange={(e) => setEmailContent(e.target.value)}
                                placeholder="Enter your feedback..."
                                rows="6"
                                disabled={isSending}
                            />
                            <button 
                                onClick={handleSendEmail} 
                                disabled={isSending}
                                className={isSending ? 'sending' : ''}
                            >
                                {isSending ? 'Sending...' : 'Send Feedback'}
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
} 