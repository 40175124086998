import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function ProgramsList() {
    const [programs, setPrograms] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchPrograms();
    }, []);

    const fetchPrograms = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/programs`,
                { withCredentials: true }
            );
            setPrograms(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching programs:', error);
            setError('Failed to load programs');
            setLoading(false);
        }
    };

    const handleProgramClick = (programId) => {
        navigate(`/programs/${programId}`);
    };

    const organizeProgram = (program) => {
        const organized = {
            idprograms: program.id,
            name: program.name,
            description: program.description,
            businessName: program.businessName,
            image_url: program.image_url,
            weekCount: 0,
            weeks: program.weeks || {}
        };

        if (program.weeks) {
            organized.weekCount = Object.keys(program.weeks).length;
        }

        return organized;
    };

    if (loading) return <div className="loading">Loading...</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>All Programs</h1>
            </div>

            {error && <div className="error-message">{error}</div>}

            <div className="list-container">
                {programs.length > 0 ? (
                    programs.map(program => {
                        const organizedProgram = organizeProgram(program);
                        console.log(organizedProgram);
                        return (
                            <div 
                                key={organizedProgram.id} 
                                className="list-item clickable"
                                onClick={() => handleProgramClick(organizedProgram.idprograms)}
                            >
                                <div className="user-info">
                                    <h3>{organizedProgram.name}</h3>
                                    {organizedProgram.description && (
                                        <p className="user-email">{organizedProgram.description}</p>
                                    )}
                                    {organizedProgram.businessName && (
                                        <p className="user-business">Business: {organizedProgram.businessName}</p>
                                    )}
                                    <p className="program-summary">
                                        Weeks: {organizedProgram.weekCount}
                                    </p>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p className="no-items-message">No programs found</p>
                )}
            </div>
        </div>
    );
} 