import { Link } from "react-router-dom";
import { logout } from "../login/Login";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";

export default function Dashboard() {
  const { hasPermission } = useAuth();



  return (
    <div className="dashboard-layout">
      <nav className="sidebar">
        <div className="sidebar-header">
          <h2>Quick Links</h2>
        </div>
        <ul className="sidebar-nav">
          <li>
            <Link to="/dashboard">
              <i className="fas fa-home"></i> Dashboard
            </Link>
          </li>
          {(hasPermission("canViewPrograms") || hasPermission("canCreatePrograms")) && (
            <li>
              <Link to="/programs">
                <i className="fas fa-list"></i> Programs
              </Link>
            </li>
          )}
          {hasPermission("canManageAllBusinesses") && (
            <li>
              <Link to="/lopsteknikk-users">
                <i className="fas fa-running"></i> Løpsteknikk
              </Link>
            </li>
          )}
          {(hasPermission("canManageActivities") || hasPermission("canViewActivities")) && (
            <li>
              <Link to="/activities">
                <i className="fas fa-tasks"></i> Activities
              </Link>
            </li>
          )}
          {hasPermission("canManagePlaylists") && (
            <li>
              <Link to="/playlists">
                <i className="fas fa-list"></i> Playlists
              </Link>
            </li>
          )}
          {hasPermission("canManageAllBusinesses") && (
            <>
              <li>
                <Link to="/levels/new">
                  <i className="fas fa-layer-group"></i> Levels
                </Link>
              </li>
              <li>
                <Link to="/environment">
                  <i className="fas fa-tree"></i> Environment
                </Link>
              </li>
              <li>
                <Link to="/businesses">
                  <i className="fas fa-building"></i> Businesses
                </Link>
              </li>
              <li>
                <Link to="/users/">
                  <i className="fas fa-user-plus"></i>User
                </Link>
              </li>
            </>
          )}
          {hasPermission("canManageOwnBusiness") && (
            <li>
              <Link to="/business">
                <i className="fas fa-building"></i> Business Info
              </Link>
            </li>
          )}
          <li>
            <Link to="/subscriptions">
              <i className="fas fa-credit-card"></i> Subscriptions
            </Link>
          </li>
          {/* <li>
                <Link to="/users/new">
                  <i className="fas fa-user-plus"></i>User
                </Link>
              </li> */}
        </ul>
      </nav>

      <div className="dashboard-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h1>Dashboard</h1>
          <button
            onClick={logout}
            style={{
              padding: "8px 16px",
              backgroundColor: "#dc3545",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <i className="fas fa-sign-out-alt"></i> Logout
          </button>
        </div>

        <div className="dashboard-section">
          <h2 className="section-title">First setup</h2>
          <div className="dashboard-grid">
            {hasPermission("canManageAllBusinesses") && (
              <>
   


                <Link to="/levels/new" className="dashboard-item">
                  <div className="dashboard-box">
                    <i className="fas fa-layer-group"></i>
                    <h2>New Level</h2>
                    <p>Create and manage intensity levels</p>
                  </div>
                </Link>

                <Link to="/steps" className="dashboard-item">
                  <div className="dashboard-box">
                    <i className="fas fa-shoe-prints"></i>
                    <h2>New Step</h2>
                    <p>Create and manage steps</p>
                  </div>
                </Link>

                <Link to="/environment" className="dashboard-item">
                  <div className="dashboard-box">
                    <i className="fas fa-tree"></i>
                    <h2>New Environment</h2>
                    <p>Create training environments</p>
                  </div>
                </Link>
              </>
            )}

          </div>
        </div>

        <div className="dashboard-section">
          <h2 className="section-title">Content Management</h2>
          <div className="dashboard-grid">
            {hasPermission("canManageAllBusinesses") && (
              <>
                <Link to="/activities/new" className="dashboard-item">
                  <div className="dashboard-box">
                    <i className="fas fa-plus-circle"></i>
                    <h2>New Activity</h2>
                    <p>Add a new activity</p>
                  </div>
                </Link>
                <Link to="/programs/new" className="dashboard-item">
                  <div className="dashboard-box">
                    <i className="fas fa-plus-circle"></i>
                    <h2>New Program</h2>
                    <p>Create and configure a new program</p>
                  </div>
                </Link>

              

                <Link to="/media/upload" className="dashboard-item">
                  <div className="dashboard-box">
                    <i className="fas fa-cloud-upload-alt"></i>
                    <h2>Upload Media</h2>
                    <p>Upload and manage media files</p>
                  </div>
                </Link>

       
              </>
            )}

            {hasPermission("canManagePlaylists") && (
              <>
                <Link to="/playlists/new" className="dashboard-item">
                  <div className="dashboard-box">
                    <i className="fas fa-plus-circle"></i>
                    <h2>New Playlist</h2>
                    <p>Create and manage playlists</p>
                  </div>
                </Link>
              </>
            )}
            {hasPermission("canViewActivities") && <>
                <Link to="/activities" className="dashboard-item">
                                    <div className="dashboard-box">
                                        <i className="fas fa-plus-circle"></i>
                                        <h2>Activities</h2>
                                        {/* <p>Add a new activity</p> */}
                                    </div>
                                </Link>
            </>}
            {hasPermission("canViewPrograms") && <>
                <Link to="/programs" className="dashboard-item">
                                    <div className="dashboard-box">
                                        <i className="fas fa-plus-circle"></i>
                                        <h2>Programs</h2>
                                        {/* <p>Create and configure a new program</p> */}
                                    </div>
                                </Link>
            </>}
          </div>
        </div>

        <div className="dashboard-section">
          <h2 className="section-title">
            {hasPermission("canManageAllBusinesses")
              ? "Business Management"
              : "Business Information"}
          </h2>
          <div className="dashboard-grid">
            {hasPermission("canManageAllBusinesses") ? (
              <>
                <Link to="/users/new" className="dashboard-item">
                  <div className="dashboard-box">
                    <i className="fas fa-user-plus"></i>
                    <h2>New User</h2>
                    <p>Add and manage system users</p>
                  </div>
                </Link>
                {/* <Link to="/businesses/" className="dashboard-item">
                                    <div className="dashboard-box">
                                        <i className="fas fa-plus-circle"></i>
                                        <h2>New Business</h2>
                                        <p>Create a new business</p>
                                    </div>
                                </Link> */}
                <Link to="/businesses" className="dashboard-item">
                  <div className="dashboard-box">
                    <i className="fas fa-building"></i>
                    <h2>All Businesses</h2>
                    <p>Manage all businesses</p>
                  </div>
                </Link>
                <Link to="/mailerlite" className="dashboard-item">
                  <div className="dashboard-box">
                    <i className="fas fa-envelope"></i>
                    <h2>Newsletter</h2>
                    <p>Manage newsletter groups and subscribers</p>
                  </div>
                </Link>
                <Link to="/exercise-of-week" className="dashboard-item">
                    <div className="dashboard-box">
                        <i className="fas fa-dumbbell"></i>
                        <h2>Exercise of the Week</h2>
                        <p>Manage featured weekly content</p>
                    </div>
                </Link>
              </>
            ) : (
              <Link to="/business" className="dashboard-item">
                <div className="dashboard-box">
                  <i className="fas fa-building"></i>
                  <h2>Business Details</h2>
                  <p>View business information</p>
                </div>
              </Link>
            )}

            <Link to="/subscriptions" className="dashboard-item">
              <div className="dashboard-box">
                <i className="fas fa-credit-card"></i>
                <h2>Subscriptions</h2>
                <p>
                  {hasPermission("canManageAllBusinesses")
                    ? "Manage subscription plans"
                    : "View subscription details"}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
