import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import '../../styles/dash/WeeklyExerciseView.css';

export default function WeeklyExerciseView() {
    const { uuid } = useParams();
    const [exercise, setExercise] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchExercise();
    }, [uuid]);

    const fetchExercise = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/weekly-exercises/${uuid}`,
                { withCredentials: true }
            );
            setExercise(response.data);
        } catch (error) {
            setError('Failed to fetch exercise details');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const getContentName = () => {
        if (!exercise || !exercise.details) return null;

        if (exercise.type === 'program' && exercise.details.programName) {
            return `Program: ${exercise.details.programName}`;
        } else if (exercise.type === 'playlist' && exercise.details.playlistName) {
            return `Playlist: ${exercise.details.playlistName}`;
        }
        return exercise.name; // fallback to generic name if specific names aren't available
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!exercise) return <div className="not-found">Exercise not found</div>;

    return (
        <div className="weekly-exercise-view">
            <div className="exercise-header">
                <h1>Exercise of the Week</h1>
                <div className="week-info">
                    <span>Week {exercise.week}, {exercise.year}</span>
                </div>
            </div>

            <div className="exercise-content">
                <div className="content-type">
                    <span className="label">Type:</span>
                    <span className="value">{exercise.type === 'program' ? 'Program' : 'Playlist'}</span>
                </div>

                <div className="content-name">
                    <h2>{getContentName()}</h2>
                </div>

                {exercise.details && (
                    <div className="content-details">
                        {exercise.details.description && (
                            <div className="description">
                                <h3>Description</h3>
                                <p>{exercise.details.description}</p>
                            </div>
                        )}
                        
                        {exercise.type === 'program' && exercise.details && (
                            <div className="program-info">
                                <div className="info-grid">
                                    {exercise.details.level_name && (
                                        <div className="info-item">
                                            <span className="label">Level:</span>
                                            <span className="value">{exercise.details.level_name}</span>
                                        </div>
                                    )}
                                    {exercise.details.step_name && (
                                        <div className="info-item">
                                            <span className="label">Step:</span>
                                            <span className="value">{exercise.details.step_name}</span>
                                        </div>
                                    )}
                                    {exercise.details.environment_name && (
                                        <div className="info-item">
                                            <span className="label">Environment:</span>
                                            <span className="value">{exercise.details.environment_name}</span>
                                        </div>
                                    )}
                                    {exercise.details.minutes && (
                                        <div className="info-item">
                                            <span className="label">Duration:</span>
                                            <span className="value">{exercise.details.minutes} minutes</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className="exercise-footer">
                <Link to="/login" className="login-link">
                    Log in to access this {exercise.type}
                </Link>
            </div>
        </div>
    );
} 