import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/components/forms.css';

export default function IndividualProgram() {
    const { id } = useParams();
    const [program, setProgram] = useState(null);
    const [activities, setActivities] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchProgram();
    }, [id]);

    const fetchProgram = async () => {
        try {
            const [programResponse, activitiesResponse] = await Promise.all([
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/programs/${id}`,
                    { withCredentials: true }
                ),
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/programs/${id}/activities`,
                    { withCredentials: true }
                )
            ]);
            
            setProgram(programResponse.data);
            setActivities(activitiesResponse.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching program:', error);
            setError('Failed to load program');
            setLoading(false);
        }
    };

    if (loading) return <div class  me="loading">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!program) return <div className="error-message">Program not found</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/programs" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Programs
                </Link>
                <h1>Program Details</h1>
            </div>

            <div className="detail-card">
                <div className="detail-header">
                    <h2>{program.name}</h2>
                    {program.businessName && (
                        <span className="business-badge">{program.businessName}</span>
                    )}
                </div>
                
                <div className="detail-content">
                    <div className="detail-row">
                        <label>Description:</label>
                        <span>{program.description}</span>
                    </div>
                    
                    <div className="program-schedule">
                        <h3>Program Schedule</h3>
                        {activities.map((week) => (
                            <div key={week.id} className="week-section">
                                <h4 className="week-title">{week.name}</h4>
                                <div className="days-grid">
                                    {week.days.map((day) => (
                                        <div key={day.id} className="day-card">
                                            <h5 className="day-title">{day.name}</h5>
                                            <div className="activities-list">
                                                {day.activities.length > 0 ? (
                                                    day.activities.map((activity) => (
                                                        <div key={activity.id} className="activity-card">
                                                            <h6 className="activity-name">{activity.name}</h6>
                                                            {activity.short_description && (
                                                                <p className="activity-short-desc">{activity.short_description}</p>
                                                            )}
                                                            <div className="activity-details">
                                                                {activity.level_name && (
                                                                    <span className="activity-level">Level: {activity.level_name}</span>
                                                                )}
                                                                {activity.step_name && (
                                                                    <span className="activity-step">Step: {activity.step_name}</span>
                                                                )}
                                                                {activity.environment_name && (
                                                                    <span className="activity-env">Environment: {activity.environment_name}</span>
                                                                )}
                                                                {activity.minutes && (
                                                                    <span className="activity-duration">{activity.minutes} minutes</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p className="no-activities">No activities scheduled</p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}