import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { logout } from '../login/Login';

export default function UserList() {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const [userRole, setUserRole] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserRole();
        fetchUsers();
    }, []);

    const fetchUserRole = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/auth/verify`,
                { withCredentials: true }
            );
            setUserRole(response.data.role);
        } catch (error) {
            console.error('Error fetching user role:', error);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/users`,
                { withCredentials: true }
            );
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error.response?.status === 401) {
                logout();
            } else {
                setError('Failed to load users');
            }
        }
    };

    const handleUserClick = (userId) => {
        navigate(`/users/${userId}`);
    };

    const isSuperAdmin = userRole === 'superadmin';

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    width: '100%'
                }}>
                    <h1>{isSuperAdmin ? 'All Users' : 'Users in Your Business'}</h1>
                    <button 
                        onClick={logout}
                        style={{
                            padding: '8px 16px',
                            backgroundColor: '#dc3545',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}
                    >
                        <i className="fas fa-sign-out-alt"></i> Logout
                    </button>
                </div>
            </div>

            {error && <div className="error-message">{error}</div>}

            <div className="list-container">
                {users.map((user) => (
                    <div 
                        key={user.idusers} 
                        className="list-item clickable"
                        onClick={() => handleUserClick(user.idusers)}
                    >
                        <div className="user-info">
                            <h3>{user.name}</h3>
                            <p className="user-email">{user.email}</p>
                            <p className="user-role">Role: {user.role}</p>
                            {user.businessName && (
                                <p className="user-business">Business: {user.businessName}</p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
} 