import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Login from './pages/login/Login';
import CreateAccount from './pages/login/CreateUser';
import ForgotPassword from './pages/login/ForgotPassword';
import Dashboard from './pages/dashboard/Dash';
import NewProgram from './pages/programs/NewProgram';
import NewActivity from './pages/activities/NewActivity';
import UploadMedia from './pages/media/UploadMedia';
import NewUser from './pages/users/NewUser';
import NewPlaylist from './pages/playlists/NewPlaylist';
import UserList from './pages/users/UserList';
import BusinessList from './pages/businesses/BusinessList';
import IndividualUser from './pages/users/IndividualUser';
import IndividualBusiness from './pages/businesses/IndividualBusiness';
import IndividualPlaylist from './pages/playlists/IndividualPlaylist';
import IndividualProgram from './pages/programs/IndividualProgram';
import IndividualActivity from './pages/activities/IndividualActivity';
import ProgramsList from './pages/programs/ProgramsList';
import ActivitiesList from './pages/activities/ActivitiesList';
import PlaylistsList from './pages/playlists/PlaylistsList';
import NewLevel from './pages/levels/NewLevel';
import NewStep from './pages/steps/NewStep';
import NewEnvironment from './pages/environment/NewEnvironment';
import NewSubscription from './pages/subscriptions/NewSubscription';
import { ProtectedRoute } from './components/ProtectedRoute';
import MailerLite from './pages/mailerlite/MailerLite';
import ExerciseOfWeek from './pages/exerciseOfWeek/ExerciseOfWeek';
import WeeklyExerciseView from './pages/exerciseOfWeek/WeeklyExerciseView';
import ProfileSubmission from './pages/profile/ProfileSubmission';
import Join from './pages/join/Join';
// import LevelsList from './pages/levels/LevelsList';
import LopsteknikkList from './pages/lopsteknikk/LopsteknikkList';

function App() {
  return (
    <div className="wrapper">
      <AuthProvider>

      <Routes>  
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route 
          path="/dashboard" 
          element={
            <ProtectedRoute 
              element={<Dashboard />} 
              requiredPermissions={['canViewDashboard']} 
            />
          } 
        />
        <Route path="/programs/new" element={<NewProgram />} />
        <Route path="/activities/new" element={<NewActivity />} />
        <Route path="/playlists/new" element={<NewPlaylist />} />
        <Route path="/users/new" element={<NewUser />} />
        <Route path="/media/upload" element={<UploadMedia />} />
        <Route path="/users" element={<UserList />} />
        <Route path="/businesses" element={<BusinessList />} />
        
      <Route path="/users/:id" element={<IndividualUser />} />
      <Route path="/businesses/:id" element={<IndividualBusiness />} />
      <Route path="/playlists/:id" element={<IndividualPlaylist />} />
      <Route path="/programs/:id" element={<IndividualProgram />} />
      <Route path="/activities/:id" element={<IndividualActivity />} />

      <Route path="/programs" element={<ProgramsList />} />
      <Route path="/activities" element={<ActivitiesList />} />
      <Route path="/playlists" element={<PlaylistsList />} />
      <Route path="/levels/new" element={<NewLevel />} />
      <Route path="/steps" element={<NewStep />} />
      <Route path="/environment" element={<NewEnvironment />} />
      <Route path="/subscriptions" element={<NewSubscription />} />
      <Route path="/mailerlite" element={<MailerLite />} />
      <Route 
        path="/exercise-of-week" 
        element={
            <ProtectedRoute 
                element={<ExerciseOfWeek />} 
                requiredPermissions={['canManageAllBusinesses']} 
            />
        } 
      />
      <Route path="/weekly-exercise/:uuid" element={<WeeklyExerciseView />} />
      <Route path="/join" element={<ProfileSubmission />} />
      <Route path="/lopsteknikk" element={<Join />} />
      {/* <Route path="/levels" element={<LevelsList />} /> */}
      <Route path="/lopsteknikk-users" element={<LopsteknikkList />} />
      </Routes>
      </AuthProvider>

    </div>
  );
}

export default App;
