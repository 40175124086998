import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';

export default function IndividualActivity() {
    const { id } = useParams();
    const [activity, setActivity] = useState(null);
    const [playlists, setPlaylists] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchActivity();
    }, [id]);

    const fetchActivity = async () => {
        try {
            const [activityResponse, playlistsResponse] = await Promise.all([
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/activities/${id}`,
                    { withCredentials: true }
                ),
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/activities/${id}/playlists`,
                    { withCredentials: true }
                )
            ]);
            
            setActivity(activityResponse.data);
            setPlaylists(playlistsResponse.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching activity:', error);
            setError('Failed to load activity');
            setLoading(false);
        }
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!activity) return <div className="error-message">Activity not found</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/activities" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Activities
                </Link>
                <h1>Activity Details</h1>
            </div>

            <div className="detail-card">
                <div className="detail-header">
                    <h2>{activity.name}</h2>
                    <div className="badge-container">
                        {activity.programName && (
                            <span className="program-badge">{activity.programName}</span>
                        )}
                        {activity.businessName && (
                            <span className="business-badge">{activity.businessName}</span>
                        )}
                    </div>
                </div>
                
                <div className="detail-content">
                    <div className="detail-row">
                        <label>Description:</label>
                        <span>{activity.description}</span>
                    </div>
                    
                    <div className="detail-section">
                        <h3>Playlists in this Activity</h3>
                        <div className="associated-playlists">
                            {playlists.length > 0 ? (
                                playlists.map(playlist => (
                                    <div key={playlist.idplaylists} className="playlist-item">
                                        <span>{playlist.name}</span>
                                        <span className="playlist-duration">
                                            {playlist.duration} minutes
                                        </span>
                                    </div>
                                ))
                            ) : (
                                <p>No playlists in this activity</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}