import { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/components/forms.css";
import "../../styles/user/login.css";
import axios from "axios";

export const logout = async () => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/auth/logout`, 
      {}, 
      { withCredentials: true }
    );
    window.location.href = "/login";
  } catch (error) {
    console.error('Logout failed:', error);
  }
};

const Login = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { login, user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // If user is already authenticated, redirect to dashboard
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/login`,
        credentials,
        { withCredentials: true }
      );
      const data = response.data;
      if (data.success) {
        setSuccess(data.message);
        setError("");
        // Let AuthContext handle the user state
        await login(data.user);
        navigate("/dashboard");
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError(error.response?.data?.message || "Login failed");
    }
  };

  // Show loading state while checking authentication
  if (loading) return <div className="loading">Loading...</div>;

  // If user is already authenticated, don't render the login form
  if (user) return null;

  return (
    <div>
      <div className="login-container">
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Login</h2>
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          <input
            type="email"
            placeholder="Email"
            value={credentials.email}
            onChange={(e) =>
              setCredentials({ ...credentials, email: e.target.value })
            }
          />
          <input
            type="password"
            placeholder="Password"
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
          />
          <button type="submit">Login</button>
          <Link className="create-account-link" to="/create-account">
            Create Account
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Login;
