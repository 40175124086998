import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export const ProtectedRoute = ({ 
  element: Component, 
  requiredPermissions = [], 
  redirectPath = '/login' 
}) => {
  const { isAuthenticated, checkMultiplePermissions } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  if (requiredPermissions.length > 0 && !checkMultiplePermissions(requiredPermissions)) {
    return <Navigate to="/dashboard" replace />;
  }

  return Component;
};