import { useState, useEffect } from 'react';
import axios from 'axios';

export default function MediaSelector({ onSelect, selectedUrl, folder = 'audio/128kbps', accept = 'audio/*' }) {
    const [mediaFiles, setMediaFiles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (searchTerm.length >= 2) {
            setLoading(true);
            fetchMediaFiles();
        } else {
            setMediaFiles([]);
        }
    }, [searchTerm, folder]);

    const fetchMediaFiles = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/media?folder=${folder}`,
                { withCredentials: true }
            );

            let filteredFiles = response.data.blobs.filter(file =>
                file.name.toLowerCase().includes(searchTerm.toLowerCase())
            );  
            if(response.data.blobs2){
                const filteredFiles2 = response.data.blobs2.filter(file =>
                    file.name.toLowerCase().includes(searchTerm.toLowerCase())
                );
                filteredFiles = [...filteredFiles, ...filteredFiles2];
            }


        
            
            setMediaFiles(filteredFiles);
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch media files');
            console.error('Error:', error);
            setLoading(false);
        }
    };

    return (
        <div className="media-selector">
            <div className="search-bar">
                <input
                    type="text"
                    placeholder={`Search ${folder.includes('video') ? 'videos' : 'audio files'}...`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>

            {loading ? (
                <div className="loading">Loading...</div>
            ) : error ? (
                <div className="error">{error}</div>
            ) : (
                <div className="media-list">
                    {searchTerm.length < 2 ? (
                        <p className="search-prompt">Type at least 2 characters to search</p>
                    ) : mediaFiles.length === 0 ? (
                        <p>No {folder.includes('video') ? 'videos' : 'audio files'} found</p>
                    ) : (
                        mediaFiles.map((file) => (
                            <div 
                                key={file.id} 
                                className={`media-item ${selectedUrl === file.url ? 'selected' : ''}`}
                                onClick={() => onSelect(file.url)}
                            >
                                {folder.includes('video') ? (
                                    <div className="video-preview">
                                        <i className="fas fa-video"></i>
                                    </div>
                                ) : (
                                    <div className="audio-preview">
                                        <i className="fas fa-music"></i>
                                    </div>
                                )}
                                <div className="media-info">
                                    <span className="media-name">{file.name}</span>
                                    {selectedUrl === file.url && (
                                        <span className="selected-indicator">
                                            <i className="fas fa-check"></i>
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            )}

            <style jsx>{`
                .media-selector {
                    border: 1px solid #e2e8f0;
                    border-radius: 8px;
                    padding: 1rem;
                    background: white;
                }

                .search-bar {
                    margin-bottom: 1rem;
                }

                .search-input {
                    width: 100%;
                    padding: 0.5rem;
                    border: 1px solid #e2e8f0;
                    border-radius: 4px;
                }

                .media-list {
                    max-height: 300px;
                    overflow-y: auto;
                }

                .media-item {
                    display: flex;
                    align-items: center;
                    padding: 0.75rem;
                    border: 1px solid #e2e8f0;
                    border-radius: 4px;
                    margin-bottom: 0.5rem;
                    cursor: pointer;
                    transition: all 0.2s;
                }

                .media-item:hover {
                    background: #f7fafc;
                }

                .media-item.selected {
                    background: #ebf8ff;
                    border-color: #4299e1;
                }

                .video-preview, .audio-preview {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #f7fafc;
                    border-radius: 4px;
                    margin-right: 1rem;
                }

                .video-preview i {
                    color: #4a5568;
                    font-size: 1.2rem;
                }

                .audio-preview i {
                    color: #4a5568;
                    font-size: 1.2rem;
                }

                .media-info {
                    flex: 1;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .media-name {
                    font-size: 0.9rem;
                    color: #4a5568;
                }

                .selected-indicator {
                    color: #48bb78;
                }

                .loading, .error {
                    text-align: center;
                    padding: 1rem;
                    color: #718096;
                }

                .search-prompt {
                    text-align: center;
                    color: #718096;
                    padding: 1rem;
                    font-style: italic;
                }
            `}</style>
        </div>
    );
} 