import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';


export default function IndividualBusiness() {
    const { id } = useParams();
    const [business, setBusiness] = useState(null);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [userRole, setUserRole] = useState(null);
    const { hasPermission } = useAuth();
    console.log(hasPermission, "HAS PERMISSION");

    useEffect(() => {
        fetchUserRole();
        fetchBusinessDetails();
    }, [id]);

    const fetchUserRole = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/auth/verify`,
                { withCredentials: true }
            );
            setUserRole(response.data.role);
            console.log(response.data.role, "USER ROLE");
        } catch (error) {
            console.error('Error fetching user role:', error);
        }
    };

    const fetchBusinessDetails = async () => {
        try {
            let businessResponse, usersResponse;
            console.log(hasPermission('viewAnyBusiness'), "HAS PERMISSION");
            if (!hasPermission('viewAnyBusiness')) {
                console.log("SUPERADMIN FLOW");
                // Superadmin flow
                [businessResponse, usersResponse] = await Promise.all([
                    axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/api/businesses/${id}`,
                        { withCredentials: true }
                    ),
                    axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/api/businesses/${id}/users`,
                        { withCredentials: true }
                    )
                ]);
            } else {
                // Regular admin flow - fetch their own business details
                [businessResponse, usersResponse] = await Promise.all([
                    axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/api/businesses/current`,
                        { withCredentials: true }
                    ),
                    axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/api/businesses/current/users`,
                        { withCredentials: true }
                    )
                ]);
            }

            if (businessResponse?.data) {
                console.log(businessResponse.data, "BUSINESS RESPONSE");
                setBusiness(businessResponse.data);
                setUsers(usersResponse.data || []);
            } else {
                setError('No business data found');
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching business:', error);
            setError('Failed to load business details. ' + (error.response?.data?.message || ''));
            setLoading(false);
        }
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!business) return <div className="error-message">Business not found</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Your Business Details</h1>
            </div>

            <div className="detail-card">
                <div className="detail-header">
                    <h2>{business.name}</h2>
                    <span className="seats-badge">
                        {business.seats_available} seats available
                    </span>
                </div>
                
                <div className="detail-content">
                    <div className="detail-row">
                        <label>Business Code:</label>
                        <span>{business.code}</span>
                    </div>

                    <div className="subscription-details">
                        <h3>Subscription Information</h3>
                        <div className="detail-row">
                            <label>Plan:</label>
                            <span>{business.subscription_name || 'Standard Plan'}</span>
                        </div>
                        {business.subscription_price && (
                            <div className="detail-row">
                                <label>Price:</label>
                                <span>NOK {business.subscription_price}</span>
                            </div>
                        )}
                        {business.subscription_duration_months && (
                            <div className="detail-row">
                                <label>Duration:</label>
                                <span>{business.subscription_duration_months} months</span>
                            </div>
                        )}
                    </div>
                    
                    <div className="detail-section">
                        <h3>Business Users</h3>
                        <div className="associated-users">
                            {users.length > 0 ? (
                                users.map(user => (
                                    <Link 
                                        to={`/users/${user.idusers}`} 
                                        key={user.idusers} 
                                        className="user-item"
                                    >
                                        <div className="user-info">
                                            <span className="user-name">{user.name}</span>
                                            <span className="user-email">{user.email}</span>
                                            <span className="user-role">{user.role}</span>
                                        </div>
                                        <i className="fas fa-chevron-right"></i>
                                    </Link>
                                ))
                            ) : (
                                <p>No users found in your business</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .detail-card {
                    background: white;
                    border-radius: 8px;
                    padding: 24px;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                    margin-top: 20px;
                }

                .detail-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 24px;
                    padding-bottom: 16px;
                    border-bottom: 1px solid #eee;
                }

                .seats-badge {


                    padding: 6px 12px;
                    border-radius: 16px;
                    font-size: 14px;
                }

                .detail-row {
                    display: flex;
                    margin-bottom: 16px;
                }

                .detail-row label {
                    width: 150px;
                    font-weight: 500;
                    color: #666;
                }

                .subscription-details {
                    margin: 24px 0;
                    padding: 16px;
                    background: #f8f9fa;
                    border-radius: 8px;
                }

                .associated-users {
                    margin-top: 16px;
                }

                .user-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 12px 16px;
                    border: 1px solid #eee;
                    border-radius: 6px;
                    margin-bottom: 8px;
                    text-decoration: none;
                    color: inherit;
                    transition: all 0.2s;
                }

                .user-item:hover {
                    background: #f8f9fa;
                    border-color: #dee2e6;
                }

                .user-info {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                }

                .user-name {
                    font-weight: 500;
                }

                .user-email {
                    color: #666;
                    font-size: 14px;
                }

                .user-role {
                    font-size: 12px;
                    background: #e9ecef;
                    padding: 2px 8px;
                    border-radius: 12px;
                    color: #495057;
                }
            `}</style>
        </div>
    );
} 