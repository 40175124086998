import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DeleteModal from '../../components/DeleteModal';

// Create a new SortableActivity component using @dnd-kit
const SortableActivity = ({ activity, removeActivity }) => {
    
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id: activity.id.toString() });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    const handleRemove = (e) => {
        e.stopPropagation();
        removeActivity(activity.id);
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={`selected-activity ${isDragging ? 'dragging' : ''}`}
            {...attributes}
            {...listeners}
        >
            <i className="fas fa-grip-vertical drag-handle"></i>
            <span>{activity.name}</span>
            <button
                type="button"
                onClick={handleRemove}
                className="remove-activity-btn"
            >
                ×
            </button>
        </div>
    );
};

export default function NewPlaylist() {
    const [formData, setFormData] = useState({
        name: '',
    });
    const [playlists, setPlaylists] = useState([]);
    const [activities, setActivities] = useState([]);
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [msg, setMsg] = useState('');
    const [error, setError] = useState('');
    const [editingId, setEditingId] = useState(null);
    const [editData, setEditData] = useState({
        name: '',
        activities: []
    });
    const [deleteConfirm, setDeleteConfirm] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [editSearchTerm, setEditSearchTerm] = useState('');

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        fetchPlaylists();
        fetchActivities();
    }, []);

    const fetchPlaylists = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/playlists`,
                { withCredentials: true }
            );
            setPlaylists(response.data);
        } catch (error) {
            console.error('Error fetching playlists:', error);
            setError('Failed to load playlists');
        }
    };

    const fetchActivities = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/activities`,
                { withCredentials: true }
            );
            setActivities(response.data);
        } catch (error) {
            console.error('Error fetching activities:', error);
            setError('Failed to load activities');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleActivitySelect = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => ({
            id: option.value,
            name: option.label
        }));
        setSelectedActivities(selectedOptions);
    };

    const handleActivitySearch = (activity) => {
        console.log('Adding activity:', activity);
        const newActivity = {
            id: `${activity.idactivity}_${Date.now()}`,
            originalId: activity.idactivity,
            name: activity.name,
            order: selectedActivities.length
        };
        console.log('New activity to add:', newActivity);
        setSelectedActivities(prev => [...prev, newActivity]);
        setSearchTerm('');
    };

    // Handle DND-kit drag end
    const handleDragEnd = (event) => {
        const { active, over } = event;
        
        if (active.id !== over.id) {
            setSelectedActivities((items) => {
                const oldIndex = items.findIndex(item => item.id.toString() === active.id);
                const newIndex = items.findIndex(item => item.id.toString() === over.id);
                
                return arrayMove(items, oldIndex, newIndex).map((item, index) => ({
                    ...item,
                    order: index
                }));
            });
        }
    };

    const handleEditDragEnd = (event) => {
        const { active, over } = event;
        
        if (active.id !== over.id) {
            setEditData(prev => {
                const oldIndex = prev.activities.findIndex(item => item.id.toString() === active.id);
                const newIndex = prev.activities.findIndex(item => item.id.toString() === over.id);
                
                return {
                    ...prev,
                    activities: arrayMove(prev.activities, oldIndex, newIndex).map((item, index) => ({
                        ...item,
                        order: index
                    }))
                };
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/playlists`,
                {
                    name: formData.name,
                    activities: selectedActivities.map((activity, index) => ({
                        id: activity.originalId,
                        order: index
                    }))
                },
                { withCredentials: true }
            );

            if (response.status === 201) {
                setMsg('Playlist created successfully!');
                setFormData({ name: '' });
                setSelectedActivities([]);
                fetchPlaylists();
                setTimeout(() => setMsg(''), 3000);
            }
        } catch (error) {
            console.error('Error creating playlist:', error);
            setError(error.response?.data?.message || 'Failed to create playlist');
            setTimeout(() => setError(''), 3000);
        }
    };

    const handleEdit = (playlist) => {
        setEditingId(playlist.idplaylist);
        setEditData({
            name: playlist.name,
            activities: playlist.activities.map((activity, index) => ({
                id: `${activity.id}_${Date.now()}_${index}`,
                originalId: activity.id,
                name: activity.name,
                order: index
            })) || []
        });
    };

    const handleEditActivitySearch = (activity) => {
        console.log('Adding activity to edit:', activity);
        const newActivity = {
            id: `${activity.idactivity}_${Date.now()}`,
            originalId: activity.idactivity,
            name: activity.name,
            order: editData.activities.length
        };
        console.log('New activity to add to edit:', newActivity);
        setEditData(prev => ({
            ...prev,
            activities: [...prev.activities, newActivity]
        }));
        setEditSearchTerm('');
    };

    const handleUpdate = async (id) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/playlists/${id}`,
                {
                    name: editData.name,
                    activities: editData.activities.map((activity, index) => ({
                        id: activity.originalId,
                        order: index
                    }))
                },
                { withCredentials: true }
            );

            if (response.status === 200) {
                setMsg('Playlist updated successfully!');
                setEditingId(null);
                // fetchPlaylists();
                // setTimeout(() => setMsg(''), 3000);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error updating playlist:', error);
            setError('Failed to update playlist');
            setTimeout(() => setError(''), 3000);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/playlists/${id}`,
                { withCredentials: true }
            );
            
            if (response.status === 200) {
                setDeleteConfirm(null);
                setMsg('Playlist deleted successfully!');
                fetchPlaylists();
                setTimeout(() => setMsg(''), 3000);
            }
        } catch (error) {
            console.error('Error deleting playlist:', error);
            setError('Failed to delete playlist');
            setTimeout(() => setError(''), 3000);
        }
    };

    const removeSelectedActivity = (activityId) => {
        console.log('Removing activity:', activityId);
        setSelectedActivities(prev => 
            prev.filter(activity => activity.id !== activityId)
                .map((activity, index) => ({
                    ...activity,
                    order: index
                }))
        );
    };

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Create New Playlist</h1>
            </div>

            {msg && <div className="success-message">{msg}</div>}
            {error && <div className="error-message">{error}</div>}

            <form className="form-container" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Playlist Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="activities">Search Activities</label>
                    <div className="search-container">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search activities..."
                            className="search-input"
                        />
                        {searchTerm && (
                            <div className="search-results">
                                {activities
                                    .filter(activity => 
                                        activity.name.toLowerCase().includes(searchTerm.toLowerCase())
                                    )
                                    .map(activity => (
                                        <div
                                            key={activity.idactivity}
                                            className="search-result-item"
                                            onClick={() => handleActivitySearch(activity)}
                                        >
                                            {activity.name}
                                        </div>
                                    ))
                                }
                            </div>
                        )}
                    </div>
                </div>

                <div className="selected-activities" >
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext
                            items={selectedActivities.map(a => a.id.toString())}
                            strategy={verticalListSortingStrategy}
                        >
                            {selectedActivities.map((activity) => (
                            
                                <SortableActivity
                                    key={activity.id}
                                    activity={activity}
                                    removeActivity={removeSelectedActivity}
                                />
                            ))}
                        </SortableContext>
                    </DndContext>
                </div>

                <div className="form-actions">
                    <button type="submit" className="submit-button">
                        Create Playlist
                    </button>
                    <Link to="/dashboard" className="cancel-button">
                        Cancel
                    </Link>
                </div>
            </form>

            <div className="playlists-list">
                <h2>Existing Playlists</h2>
                <div className="list-container">
                    {playlists.map((playlist) => (
                        <div key={playlist.idplaylist} className="list-item">
                            {editingId === playlist.idplaylist ? (
                                <div className="edit-form">
                                    <input
                                        type="text"
                                        value={editData.name}
                                        onChange={(e) => setEditData({
                                            ...editData,
                                            name: e.target.value
                                        })}
                                        className="edit-input"
                                        placeholder="Playlist Name"
                                    />
                                    <div className="search-container">
                                        <input
                                            type="text"
                                            value={editSearchTerm}
                                            onChange={(e) => setEditSearchTerm(e.target.value)}
                                            placeholder="Search activities..."
                                            className="search-input"
                                        />
                                        {editSearchTerm && (
                                            <div className="search-results">
                                                {activities
                                                    .filter(activity => 
                                                        activity.name.toLowerCase().includes(editSearchTerm.toLowerCase())
                                                    )
                                                    .map(activity => (
                                                        <div
                                                            key={activity.idactivity}
                                                            className="search-result-item"
                                                            onClick={() => handleEditActivitySearch(activity)}
                                                        >
                                                            {activity.name}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )}
                                    </div>
                                    <div className="selected-activities">
                                        <DndContext
                                            sensors={sensors}
                                            collisionDetection={closestCenter}
                                            onDragEnd={handleEditDragEnd}
                                        >
                                            <SortableContext
                                                items={editData.activities.map(a => a.id.toString())}
                                                strategy={verticalListSortingStrategy}
                                            >
                                                {editData.activities.map((activity) => (
                                                    <SortableActivity
                                                        key={activity.id}
                                                        activity={activity}
                                                        removeActivity={(id) => 
                                                            setEditData(prev => ({
                                                                ...prev,
                                                                activities: prev.activities.filter(a => a.id !== id)
                                                            }))
                                                        }
                                                    />
                                                ))}
                                            </SortableContext>
                                        </DndContext>
                                    </div>
                                </div>
                            ) : (
                                <div className="playlist-info">
                                    <h3>{playlist.name}</h3>
                                    {playlist.activities && (
                                        <div className="playlist-activities">
                                            {playlist.activities.map((activity, index) => (
                                                <span key={activity.id} className="activity-tag">
                                                    {activity.name}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="list-item-actions">
                                {editingId === playlist.idplaylist ? (
                                    <>
                                        <button 
                                            className="save-button"
                                            onClick={() => handleUpdate(playlist.idplaylist)}
                                        >
                                            <i className="fas fa-save"></i>
                                        </button>
                                        <button 
                                            className="cancel-button"
                                            onClick={() => setEditingId(null)}
                                        >
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button 
                                            className="edit-button"
                                            onClick={() => handleEdit(playlist)}
                                        >
                                            <i className="fas fa-pencil-alt"></i>
                                        </button>
                                        <button 
                                            className="delete-button"
                                            onClick={() => setDeleteConfirm(playlist)}
                                        >
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {deleteConfirm && (
                <DeleteModal 
                    isOpen={!!deleteConfirm}
                    onClose={() => setDeleteConfirm(null)}
                    onConfirm={() => handleDelete(deleteConfirm.idplaylist)}
                    itemName={`playlist "${deleteConfirm.name}"`}
                />
            )}
        </div>
    );
} 