import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';

export default function IndividualPlaylist() {
    const { id } = useParams();
    const [playlist, setPlaylist] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchPlaylist();
    }, [id]);

    const fetchPlaylist = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/playlists/${id}`,
                { withCredentials: true }
            );
            setPlaylist(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching playlist:', error);
            setError('Failed to load playlist');
            setLoading(false);
        }
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!playlist) return <div className="error-message">Playlist not found</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/playlists" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Playlists
                </Link>
                <h1>Playlist Details</h1>
            </div>

            <div className="detail-card">
                <div className="detail-header">
                    <h2>{playlist.name}</h2>
                    <div className="badge-container">
                        {playlist.activityName && (
                            <span className="activity-badge">{playlist.activityName}</span>
                        )}
                        {playlist.programName && (
                            <span className="program-badge">{playlist.programName}</span>
                        )}
                        {playlist.businessName && (
                            <span className="business-badge">{playlist.businessName}</span>
                        )}
                    </div>
                </div>
                
                <div className="detail-content">
                    <div className="detail-row">
                        <label>Description:</label>
                        <span>{playlist.description}</span>
                    </div>
                    
                    <div className="detail-row">
                        <label>Duration:</label>
                        <span>{playlist.duration} minutes</span>
                    </div>
                    
                    <div className="detail-row">
                        <label>Created:</label>
                        <span>{new Date(playlist.created_at).toLocaleDateString()}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}