import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';

export default function NewUser() {
    const { hasPermission } = useAuth();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        role: 'user',
        businessId: '',
        pricingId: ''
    });
    const [users, setUsers] = useState([]);
    const [msg, setMsg] = useState('');
    const [error, setError] = useState('');
    const [editingId, setEditingId] = useState(null);
    const [editData, setEditData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        businessId: ''
    });
    const [deleteConfirm, setDeleteConfirm] = useState(null);
    const [roles, setRoles] = useState([]);
    const [currentBusiness, setCurrentBusiness] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [businesses, setBusinesses] = useState([]);
    const [businessFormData, setBusinessFormData] = useState({
        name: '',
        code: '',
        seatsAvailable: '',
        pricingId: ''
    });
    const [businessMsg, setBusinessMsg] = useState('');
    const [businessError, setBusinessError] = useState('');
    const [pricingPlans, setPricingPlans] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserRole();
        fetchUsers();
        fetchRoles();
        fetchPricingPlans();
        if (userRole === 'superadmin') {
            fetchBusinesses();
        } else if (userRole !== 'superadmin') {
            fetchCurrentUserBusiness();
        }
    }, [userRole]);

    const fetchCurrentUserBusiness = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/current-business`,
                { withCredentials: true }
            );
            setCurrentBusiness(response.data);
            // Auto-set the businessId in formData
            setFormData(prev => ({
                ...prev,
                businessId: response.data.idbusiness
            }));
        } catch (error) {
            console.error('Error fetching current business:', error);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/users`,
                { withCredentials: true }
            );
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to load users');
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/roles`,
                { withCredentials: true }
            );
            setRoles(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
            setError('Failed to load roles');
        }
    };

    const fetchUserRole = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/auth/verify`,
                { withCredentials: true }
            );
            setUserRole(response.data.role);
        } catch (error) {
            console.error('Error fetching user role:', error);
        }
    };

    const fetchBusinesses = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/businesses`,
                { withCredentials: true }
            );
            setBusinesses(response.data);
        } catch (error) {
            console.error('Error fetching businesses:', error);
            setError('Failed to load businesses');
        }
    };

    const fetchPricingPlans = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/pricing-plans`,
                { withCredentials: true }
            );
            setPricingPlans(response.data);
        } catch (error) {
            console.error('Error fetching pricing plans:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/users`,
                formData,
                { withCredentials: true }
            );

            if (response.status === 201) {
                setMsg('User created successfully!');
                // If payment link exists, redirect user
                if (response.data.paymentLink) {
                    window.location.href = response.data.paymentLink;
                } else {
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                        phone: '',
                        role: 'user',
                        businessId: currentBusiness?.idbusiness || '',
                        pricingId: ''
                    });
                    fetchUsers();
                    setTimeout(() => setMsg(''), 3000);
                }
            }
        } catch (error) {
            console.error('Error creating user:', error);
            setError(error.response?.data?.message || 'Failed to create user');
            setTimeout(() => setError(''), 3000);
        }
    };

    const handleUserClick = (userId) => {
        navigate(`/users/${userId}`);
    };

    const filteredRoles = roles.filter(role => {
        if (hasPermission('canManageRoles')) return true;
        return role.role !== 'superadmin'; // Regular admins can't create superadmins
    });

    const handleBusinessChange = (e) => {
        const { name, value } = e.target;
        setBusinessFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleBusinessSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/businesses`,
                businessFormData,
                { withCredentials: true }
            );

            if (response.status === 201) {
                setBusinessMsg('Business created successfully!');
                setBusinessFormData({
                    name: '',
                    code: '',
                    seatsAvailable: '',
                    pricingId: ''
                });
                fetchBusinesses();
                setTimeout(() => setBusinessMsg(''), 3000);
            }
        } catch (error) {
            console.error('Error creating business:', error);
            setBusinessError(error.response?.data?.message || 'Failed to create business');
            setTimeout(() => setBusinessError(''), 3000);
        }
    };

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Add New User</h1>
            </div>

            {msg && <div className="success-message">{msg}</div>}
            {error && <div className="error-message">{error}</div>}

            {userRole === 'superadmin' && (
                <div className="section-container">
                    <h2>Create New Business</h2>
                    {businessMsg && <div className="success-message">{businessMsg}</div>}
                    {businessError && <div className="error-message">{businessError}</div>}

                    <form className="form-container" onSubmit={handleBusinessSubmit}>
                        <div className="form-grid">
                            <div className="form-group">
                                <label htmlFor="businessName">Business Name</label>
                                <input
                                    type="text"
                                    id="businessName"
                                    name="name"
                                    value={businessFormData.name}
                                    onChange={handleBusinessChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="businessCode">Business Code</label>
                                <input
                                    type="text"
                                    id="businessCode"
                                    name="code"
                                    value={businessFormData.code}
                                    onChange={handleBusinessChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="seatsAvailable">Seats Available</label>
                                <input
                                    type="number"
                                    id="seatsAvailable"
                                    name="seatsAvailable"
                                    value={businessFormData.seatsAvailable}
                                    onChange={handleBusinessChange}
                                    required
                                    min="1"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="pricingId">Pricing Plan</label>
                                <select
                                    id="pricingId"
                                    name="pricingId"
                                    value={businessFormData.pricingId}
                                    onChange={handleBusinessChange}
                                    required
                                    className="form-select"
                                >
                                    <option value="">Select a pricing plan</option>
                                    {pricingPlans.map(pricing => {
                                        let priceDisplay = '';
                                        if (pricing.tiers_mode) {
                                            const lowestTier = pricing.tiers && pricing.tiers[0];
                                            if (lowestTier) {
                                                priceDisplay = `from ${pricing.currency?.toUpperCase() || 'NOK'} ${lowestTier.amount}`;
                                            }
                                        } else {
                                            priceDisplay = `${pricing.currency?.toUpperCase() || 'NOK'} ${pricing.price}`;
                                        }

                                        return (
                                            <option 
                                                key={pricing.idpricing} 
                                                value={pricing.idpricing}
                                            >
                                                {pricing.name} - {priceDisplay} / {pricing.payment_interval || 'month'}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="form-actions">
                            <button type="submit" className="submit-button">
                                Create Business
                            </button>
                        </div>
                    </form>

                    <div className="businesses-list">
                        <div className="section-header">
                            <h2>Existing Businesses</h2>
                            <Link to="/businesses" className="view-all-link">
                                View All <i className="fas fa-arrow-right"></i>
                            </Link>
                        </div>
                        <div className="list-container">
                            {businesses.map((business) => (
                                <div 
                                    key={business.idbusiness} 
                                    className="list-item"
                                >
                                    <div className="business-info">
                                        <h3>{business.name}</h3>
                                        <p>Code: {business.code}</p>
                                        <p>Seats Available: {business.seats_available}</p>
                                        {business.pricing_name && (
                                            <p className="business-pricing">
                                                Pricing: {business.pricing_name}
                                            </p>
                                        )}
                                    </div>
                                    <Link 
                                        to={`/businesses/${business.idbusiness}`}
                                        className="view-details-button"
                                    >
                                        View Details
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <form className="form-container" onSubmit={handleSubmit}>
                <div className="form-grid">
                    <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="+47 XXX XX XXX"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="role">Role</label>
                        <select
                            id="role"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select a role</option>
                            {filteredRoles.map(role => (
                                <option key={role.idrole} value={role.role}>
                                    {role.role}
                                </option>
                            ))}
                        </select>
                    </div>

                    {!formData.businessId && (
                        <div className="form-group">
                            <label htmlFor="pricingId">Pricing Plan</label>
                            <select
                                id="pricingId"
                                name="pricingId"
                                value={formData.pricingId}
                                onChange={handleChange}
                                required={!formData.businessId}
                                className="form-select"
                            >
                                <option value="">Select a pricing plan</option>
                                <option value="null">Free Plan</option>
                                {pricingPlans.map(pricing => {
                                    let priceDisplay = '';
                                    if (pricing.tiers_mode) {
                                        const lowestTier = pricing.tiers && pricing.tiers[0];
                                        if (lowestTier) {
                                            priceDisplay = `from ${pricing.currency?.toUpperCase() || 'NOK'} ${lowestTier.amount}`;
                                        }
                                    } else {
                                        priceDisplay = `${pricing.currency?.toUpperCase() || 'NOK'} ${pricing.price}`;
                                    }

                                    return (
                                        <option 
                                            key={pricing.idpricing} 
                                            value={pricing.idpricing}
                                        >
                                            {pricing.name} - {priceDisplay} / {pricing.payment_interval || 'month'}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}

                    {hasPermission('canManageAllBusinesses') ? (
                        <div className="form-group">
                            <label htmlFor="businessId">Business</label>
                            <select
                                id="businessId"
                                name="businessId"
                                value={formData.businessId}
                                onChange={(e) => {
                                    handleChange(e);
                                    // Clear pricing if business is selected
                                    if (e.target.value) {
                                        setFormData(prev => ({ ...prev, pricingId: '' }));
                                    }
                                }}
                            >
                                <option value="">Select a business</option>
                                {businesses.map(business => (
                                    <option key={business.idbusiness} value={business.idbusiness}>
                                        {business.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ) : currentBusiness ? (
                        <div className="form-group">
                            <label>Business</label>
                            <input
                                type="text"
                                value={currentBusiness.name}
                                disabled
                                className="disabled-input"
                            />
                        </div>
                    ) : null}
                </div>

                <div className="form-actions">
                    <button type="submit" className="submit-button">
                        Create User
                    </button>
                    <Link to="/dashboard" className="cancel-button">
                        Cancel
                    </Link>
                </div>
            </form>

            <div className="users-list">
                <div className="section-header">
                    <h2>Recent Users</h2>
                    <Link to="/users" className="view-all-link">
                        View All <i className="fas fa-arrow-right"></i>
                    </Link>
                </div>
                <div className="list-container">
                    {users.slice(0, 3).map((user) => (
                        <div 
                            key={user.idusers} 
                            className="list-item clickable"
                            onClick={() => handleUserClick(user.idusers)}
                        >
                            <div className="user-info">
                                <h3>{user.name}</h3>
                                <p className="user-email">{user.email}</p>
                                <p className="user-role">Role: {user.role}</p>
                                <p className="user-business">Business: {user.businessName}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}