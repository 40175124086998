import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import DeleteModal from '../../components/DeleteModal';
import '../../styles/components/forms.css';

export default function NewLevel() {
    const [formData, setFormData] = useState({
        name: '',
        fargekode_bakgrunn: '#ffffff',
        fargekode_tekst: '#000000',
        description: ''
    });
    const [levels, setLevels] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [editData, setEditData] = useState({
        name: '',
        fargekode_bakgrunn: '',
        fargekode_tekst: '',
        description: ''
    });
    const [deleteConfirm, setDeleteConfirm] = useState(null);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        fetchLevels();
    }, []);

    const fetchLevels = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/levels`,
                { withCredentials: true }
            );
            setLevels(response.data);
        } catch (error) {
            console.error('Error fetching levels:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/levels`,
                formData,
                { withCredentials: true }
            );

            if (response.status === 201) {
                setFormData({
                    name: '',
                    fargekode_bakgrunn: '#ffffff',
                    fargekode_tekst: '#000000',
                    description: ''
                });
                setMsg('Level created successfully');
                await fetchLevels();
            }
        } catch (error) {
            console.error('Error creating level:', error);
        }
    };

    const handleEdit = async (id) => {
        if (editingId === id) {
            try {
                const response = await axios.put(
                    `${process.env.REACT_APP_BACKEND_URL}/api/levels/${id}`,
                    editData,
                    { withCredentials: true }
                );

                if (response.status === 200) {
                    setEditingId(null);
                    setEditData({
                        name: '',
                        fargekode_bakgrunn: '',
                        fargekode_tekst: '',
                        description: ''
                    });
                    await fetchLevels();
                }
            } catch (error) {
                console.error('Error updating level:', error);
            }
        } else {
            const level = levels.find(l => l.idintensity_zone === id);
            setEditData({
                name: level.name,
                fargekode_bakgrunn: level.fargekode_bakgrunn,
                fargekode_tekst: level.fargekode_tekst,
                description: level.description || ''
            });
            setEditingId(id);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/levels/${id}`,
                { withCredentials: true }
            );
            
            if (response.status === 200) {
                setDeleteConfirm(null);
                await fetchLevels();
            }
        } catch (error) {
            console.error('Error deleting level:', error);
        }
    };

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Create New Level</h1>
            </div>

            <form className="form-container" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Level Name</label>
                    <input
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={(e) => setFormData({...formData, name: e.target.value})}
                        required
                    />
                </div>

                <div className="form-group color-inputs-row">
                    <div className="color-input-group">
                        <label htmlFor="fargekode_bakgrunn">Background Color</label>
                        <input
                            type="color"
                            id="fargekode_bakgrunn"
                            value={formData.fargekode_bakgrunn}
                            onChange={(e) => setFormData({...formData, fargekode_bakgrunn: e.target.value})}
                        />
                    </div>

                    <div className="color-input-group">
                        <label htmlFor="fargekode_tekst">Text Color</label>
                        <input
                            type="color"
                            id="fargekode_tekst"
                            value={formData.fargekode_tekst}
                            onChange={(e) => setFormData({...formData, fargekode_tekst: e.target.value})}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        value={formData.description}
                        onChange={(e) => setFormData({...formData, description: e.target.value})}
                        rows="4"
                    />
                </div>

                <button type="submit" className="submit-button">Create Level</button>
            </form>

            {msg && <div className="success-message">{msg}</div>}

            <div className="levels-list">
                <h2>Existing Levels</h2>
                <div className="list-container">
                    {levels.map((level) => (
                        <div key={level.idintensity_zone} className="list-item">
                            {editingId === level.idintensity_zone ? (
                                <div className="edit-form">
                                    <input
                                        type="text"
                                        value={editData.name}
                                        onChange={(e) => setEditData({...editData, name: e.target.value})}
                                        className="edit-input"
                                    />
                                    <label htmlFor="fargekode_bakgrunn">Background Color</label>
                                    <input
                                        type="color"
                                        id="fargekode_bakgrunn"
                                        value={editData.fargekode_bakgrunn}
                                        onChange={(e) => setEditData({...editData, fargekode_bakgrunn: e.target.value})}
                                    />
                                    <label htmlFor="fargekode_tekst">Text Color</label>
                                    <input
                                        type="color"
                                        id="fargekode_tekst"
                                        value={editData.fargekode_tekst}
                                        onChange={(e) => setEditData({...editData, fargekode_tekst: e.target.value})}
                                    />
                                    <textarea
                                        value={editData.description}
                                        onChange={(e) => setEditData({...editData, description: e.target.value})}
                                        className="edit-textarea"
                                    />
                                </div>
                            ) : (
                                <div className="level-info">
                                    <div 
                                        className="level-name"
                                        style={{
                                            backgroundColor: level.fargekode_bakgrunn,
                                            color: level.fargekode_tekst,
                                            padding: '5px 10px',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        {level.name}
                                    </div>
                                    {level.description && (
                                        <p className="level-description">{level.description}</p>
                                    )}
                                </div>
                            )}
                            <div className="list-item-actions">
                                <button
                                    className="edit-button"
                                    onClick={() => handleEdit(level.idintensity_zone)}
                                >
                                    <i className={`fas ${editingId === level.idintensity_zone ? 'fa-save' : 'fa-pencil-alt'}`}></i>
                                </button>
                                <button
                                    className="delete-button"
                                    onClick={() => setDeleteConfirm(level)}
                                >
                                    <i className="fas fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {deleteConfirm && (
                <DeleteModal
                    isOpen={!!deleteConfirm}
                    onClose={() => setDeleteConfirm(null)}
                    onConfirm={() => handleDelete(deleteConfirm.idintensity_zone)}
                    itemName={`level "${deleteConfirm.name}"`}
                />
            )}
        </div>
    );
} 