import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import DeleteModal from '../../components/DeleteModal';
import '../../styles/components/forms.css';

export default function NewSubscription() {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        currency: 'nok',
        amount: '',
        interval: 'month',
        includeMva: false,
        tiersMode: false,
        tiers: [],
        duration_months: ''
    });
    const [subscriptions, setSubscriptions] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [editData, setEditData] = useState({
        name: '',
        description: '',
        price: '',
        duration_months: ''
    });
    const [deleteConfirm, setDeleteConfirm] = useState(null);
    const [msg, setMsg] = useState('');
    const [subscribers, setSubscribers] = useState([]);
    const [newAppSubscribers, setNewAppSubscribers] = useState([]);

    useEffect(() => {
        fetchSubscriptions();
        fetchSubscribers();
        fetchNewAppSubscribers();
    }, []);

    const fetchSubscriptions = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/pricing-plans`,
                { withCredentials: true }
            );
            console.log(response.data, "subscriptions");
            setSubscriptions(response.data);
        } catch (error) {
            console.error('Error fetching subscriptions:', error);
        }
    };

    const fetchSubscribers = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/subscribers`,
                { withCredentials: true }
            );
            setSubscribers(response.data.data);
        } catch (error) {
            console.error('Error fetching subscribers:', error);
        }
    };

    const fetchNewAppSubscribers = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/newapp/subscribers`,
                { withCredentials: true }
            );
            setNewAppSubscribers(response.data.data);
        } catch (error) {
            console.error('Error fetching newApp subscribers:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/pricing-plans`,
                formData,
                { withCredentials: true }
            );

            if (response.status === 201) {
                setFormData({
                    name: '',
                    description: '',
                    price: '',
                    duration_months: ''
                });
                setMsg('Subscription created successfully');
                await fetchSubscriptions();
            }
        } catch (error) {
            console.error('Error creating subscription:', error);
        }
    };

    const handleEdit = async (id) => {
        if (editingId === id) {
            try {
                const response = await axios.put(
                    `${process.env.REACT_APP_BACKEND_URL}/api/pricing-plans/${id}`,
                    editData,
                    { withCredentials: true }
                );

                if (response.status === 200) {
                    setEditingId(null);
                    setEditData({
                        name: '',
                        description: '',
                        price: '',
                        duration_months: ''
                    });
                    await fetchSubscriptions();
                }
            } catch (error) {
                console.error('Error updating subscription:', error);
            }
        } else {
            const subscription = subscriptions.find(s => s.idsubscription === id);
            if (subscription) {
                setEditData({
                    name: subscription.name || '',
                    description: subscription.description || '',
                    price: subscription.price || '',
                    duration_months: subscription.duration_months || ''
                });
                setEditingId(id);
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/pricing-plans/${id}`,
                { withCredentials: true }
            );
            
            if (response.status === 200) {
                setDeleteConfirm(null);
                await fetchSubscriptions();
            }
        } catch (error) {
            console.error('Error deleting subscription:', error);
        }
    };

    const handleAddTier = () => {
        setFormData(prev => ({
            ...prev,
            tiers: [...prev.tiers, { maxMinutes: '', amount: '', infiniteAmount: false }]
        }));
    };

    const handleTierChange = (index, field, value) => {
        const newTiers = [...formData.tiers];
        newTiers[index] = { ...newTiers[index], [field]: value };
        setFormData(prev => ({ ...prev, tiers: newTiers }));
    };

    const removeTier = (index) => {
        setFormData(prev => ({
            ...prev,
            tiers: prev.tiers.filter((_, i) => i !== index)
        }));
    };

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Create New Subscription</h1>
            </div>
            <h1>Create pricing for Stripe Dashboard</h1>
            <form className="form-container" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Subscription Name</label>
                    <input
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={(e) => setFormData({...formData, name: e.target.value})}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        value={formData.description}
                        onChange={(e) => setFormData({...formData, description: e.target.value})}
                        rows="4"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="currency">Currency</label>
                    <select
                        id="currency"
                        value={formData.currency}
                        onChange={(e) => setFormData({...formData, currency: e.target.value})}
                        required
                    >
                        <option value="nok">NOK</option>
                        <option value="usd">USD</option>
                        <option value="eur">EUR</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="interval">Billing Interval</label>
                    <select
                        id="interval"
                        value={formData.interval}
                        onChange={(e) => setFormData({...formData, interval: e.target.value})}
                        required
                    >
                        <option value="month">Monthly</option>
                        <option value="year">Yearly</option>
                        <option value="week">Weekly</option>
                        <option value="day">Daily</option>
                    </select>
                </div>

                {!formData.tiersMode && (
                    <div className="form-group">
                        <label htmlFor="amount">Amount</label>
                        <input
                            type="number"
                            id="amount"
                            value={formData.amount}
                            onChange={(e) => setFormData({...formData, amount: e.target.value})}
                            required
                            min="0"
                            step="0.01"
                        />
                    </div>
                )}

                <div className="form-group">
                    <label className="toggle-label">
                        <span>Include MVA</span>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={formData.includeMva}
                                onChange={(e) => setFormData({...formData, includeMva: e.target.checked})}
                            />
                            <span className="toggle-slider"></span>
                        </div>
                    </label>
                </div>

                <div className="form-group">
                    <label className="toggle-label">
                        <span>Enable Tiered Pricing</span>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={formData.tiersMode}
                                onChange={(e) => setFormData({...formData, tiersMode: e.target.checked})}
                            />
                            <span className="toggle-slider"></span>
                        </div>
                    </label>
                </div>

                {formData.tiersMode && (
                    <div className="tiers-section">
                        <button 
                            type="button" 
                            onClick={handleAddTier}
                            className="add-tier-button"
                        >
                            Add Tier
                        </button>

                        {formData.tiers.map((tier, index) => (
                            <div key={index} className="tier-form">
                                <div className="max-minutes-container">
                                    <div className="form-group">
                                        <label>Max Minutes</label>
                                        <div className="input-with-toggle">
                                            <input
                                                type="number"
                                                value={tier.maxMinutes === 'inf' ? '' : tier.maxMinutes}
                                                onChange={(e) => handleTierChange(index, 'maxMinutes', e.target.value)}
                                                placeholder="Enter max minutes"
                                                disabled={tier.infiniteMinutes}
                                            />
                                            <label className="toggle-label">
                                                <span>Infinite</span>
                                                <div className="toggle-switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={tier.infiniteMinutes}
                                                        onChange={(e) => {
                                                            const newValue = e.target.checked;
                                                            handleTierChange(index, 'infiniteMinutes', newValue);
                                                            if (newValue) {
                                                                handleTierChange(index, 'maxMinutes', 'inf');
                                                            } else {
                                                                handleTierChange(index, 'maxMinutes', '');
                                                            }
                                                        }}
                                                    />
                                                    <span className="toggle-slider"></span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Amount per Unit</label>
                                    <input
                                        type="number"
                                        value={tier.amount}
                                        onChange={(e) => handleTierChange(index, 'amount', e.target.value)}
                                        min="0"
                                        step="0.01"
                                    />
                                </div>
                                <button 
                                    type="button" 
                                    onClick={() => removeTier(index)}
                                    className="remove-tier-button"
                                >
                                    Remove Tier
                                </button>
                            </div>
                        ))}
                    </div>
                )}

                <button type="submit" className="submit-button">Create Subscription</button>
            </form>

            {msg && <div className="success-message">{msg}</div>}

            <div className="subscriptions-list">
                <h2>Existing Pricing</h2>
                <div className="list-container">
                    {subscriptions.map((subscription) => (
                        <div key={subscription.idsubscription} className="list-item">
                            {editingId === subscription.idsubscription ? (
                                <div className="edit-form">
                                    <input
                                        type="text"
                                        value={editData.name}
                                        onChange={(e) => setEditData({...editData, name: e.target.value})}
                                        className="edit-input"
                                    />
                                    <textarea
                                        value={editData.description}
                                        onChange={(e) => setEditData({...editData, description: e.target.value})}
                                        className="edit-textarea"
                                    />
                                    {!subscription.tiers_mode && (
                                        <input
                                            type="number"
                                            value={editData.price}
                                            onChange={(e) => setEditData({...editData, price: e.target.value})}
                                            className="edit-input"
                                            min="0"
                                            step="0.01"
                                        />
                                    )}
                                    <div className="edit-actions">
                                        <button onClick={() => handleEdit(subscription.idsubscription)}>
                                            Save
                                        </button>
                                        <button onClick={() => setEditingId(null)}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="subscription-info">
                                        <h3>{subscription.name}</h3>
                                        {subscription.description && (
                                            <p className="subscription-description">{subscription.description}</p>
                                        )}
                                        
                                        {/* Display regular price or tiers */}
                                        {subscription.tiers_mode ? (
                                            <div className="subscription-tiers">
                                                <h4>Tiered Pricing:</h4>
                                                {subscription.tiers && subscription.tiers.length > 0 ? (
                                                    <div className="tiers-list">
                                                        {subscription.tiers.map((tier, index) => (
                                                            <div key={tier.id} className="tier-item">
                                                                <span className="tier-minutes">
                                                                    {tier.max_minutes === -1 ? 
                                                                        'Unlimited minutes' : 
                                                                        `Up to ${tier.max_minutes} minutes`
                                                                    }
                                                                </span>
                                                                <span className="tier-amount">
                                                                    {subscription.currency?.toUpperCase()} {tier.amount}
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <p>No tiers defined</p>
                                                )}
                                            </div>
                                        ) : (
                                            subscription.price && (
                                                <p className="subscription-price">
                                                    {subscription.currency?.toUpperCase() || 'NOK'} {subscription.price}
                                                </p>
                                            )
                                        )}

                                        {/* Additional subscription details */}
                                        <div className="subscription-details">
                                            {subscription.payment_interval && (
                                                <p className="subscription-interval">
                                                    Billing: {subscription.payment_interval}ly
                                                </p>
                                            )}
                                            {subscription.include_mva && (
                                                <p className="subscription-mva">Includes MVA</p>
                                            )}
                                            {subscription.stripe_price_id && (
                                                <p className="subscription-stripe-id">
                                                    Stripe Price ID: {subscription.stripe_price_id}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="list-item-actions">
                                        <button
                                            className="edit-button"
                                            onClick={() => handleEdit(subscription.idsubscription)}
                                        >
                                            <i className="fas fa-pencil-alt"></i>
                                        </button>
                                        <button
                                            className="delete-button"
                                            onClick={() => setDeleteConfirm(subscription)}
                                        >
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {deleteConfirm && (
                <DeleteModal
                    isOpen={!!deleteConfirm}
                    onClose={() => setDeleteConfirm(null)}
                    onConfirm={() => handleDelete(deleteConfirm.idsubscriptions)}
                    itemName={`subscription "${deleteConfirm.name}"`}
                />
            )}

            <div className="newsletter-subscribers-section">
                <h2>Newsletter Subscribers</h2>
                <div className="subscribers-grid">
                    {subscribers.map((subscriber) => (
                        <div key={subscriber.id} className="subscriber-card">
                            <div className="subscriber-info">
                                <h3>{subscriber.fields.name || 'No Name'}</h3>
                                <p>{subscriber.email}</p>
                                {subscriber.fields.phone && (
                                    <p>Phone: {subscriber.fields.phone}</p>
                                )}
                            </div>
                            {subscriber.images && subscriber.images.length > 0 && (
                                <div className="subscriber-images">
                                    {subscriber.images.map((image, index) => (
                                        <img 
                                            key={index}
                                            src={image.url}
                                            alt={`${subscriber.fields.name || 'Subscriber'}'s image`}
                                            className="subscriber-image"
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div className="newapp-section">
                <h2>New App Subscribers</h2>
                <div className="newapp-subscribers-grid">
                    {newAppSubscribers.map((subscriber) => (
                        <div key={subscriber.id} className="newapp-subscriber-card">
                            <div className="subscriber-info">
                                <h3>{subscriber.fields?.name || 'No Name'}</h3>
                                <p>{subscriber.email}</p>
                                {subscriber.fields?.phone && (
                                    <p>Phone: {subscriber.fields.phone}</p>
                                )}
                            </div>
                            {subscriber.images && subscriber.images.length > 0 && (
                                <div className="subscriber-images">
                                    {subscriber.images.map((image, index) => (
                                        <img 
                                            key={index}
                                            src={image.url}
                                            alt={`${subscriber.fields?.name || 'Subscriber'}'s image`}
                                            className="subscriber-image"
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
} 