import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import DeleteModal from '../../components/DeleteModal';
import '../../styles/components/forms.css';

export default function NewStep() {
    const [formData, setFormData] = useState({ name: '' });
    const [steps, setSteps] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [editData, setEditData] = useState({ name: '' });
    const [deleteConfirm, setDeleteConfirm] = useState(null);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        fetchSteps();
    }, []);

    const fetchSteps = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/steps`,
                { withCredentials: true }
            );
            setSteps(response.data);
        } catch (error) {
            console.error('Error fetching steps:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/steps`,
                formData,
                { withCredentials: true }
            );

            if (response.status === 201) {
                setFormData({ name: '' });
                setMsg('Step created successfully');
                await fetchSteps();
            }
        } catch (error) {
            console.error('Error creating step:', error);
        }
    };

    const handleEdit = async (id) => {
        if (editingId === id) {
            try {
                const response = await axios.put(
                    `${process.env.REACT_APP_BACKEND_URL}/api/steps/${id}`,
                    editData,
                    { withCredentials: true }
                );

                if (response.status === 200) {
                    setEditingId(null);
                    setEditData({ name: '' });
                    await fetchSteps();
                }
            } catch (error) {
                console.error('Error updating step:', error);
            }
        } else {
            const step = steps.find(s => s.idsteps === id);
            setEditData({ name: step.name });
            setEditingId(id);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/steps/${id}`,
                { withCredentials: true }
            );
            
            if (response.status === 200) {
                setDeleteConfirm(null);
                await fetchSteps();
            }
        } catch (error) {
            console.error('Error deleting step:', error);
        }
    };

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Create New Step</h1>
            </div>

            <form className="form-container" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Step Name</label>
                    <input
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={(e) => setFormData({ name: e.target.value })}
                        required
                    />
                </div>

                <button type="submit" className="submit-button">Create Step</button>
            </form>

            {msg && <div className="success-message">{msg}</div>}

            <div className="steps-list">
                <h2>Existing Steps</h2>
                <div className="list-container">
                    {steps.map((step) => (
                        <div key={step.idsteps} className="list-item">
                            {editingId === step.idsteps ? (
                                <input
                                    type="text"
                                    value={editData.name}
                                    onChange={(e) => setEditData({ name: e.target.value })}
                                    className="edit-input"
                                />
                            ) : (
                                <span>{step.name}</span>
                            )}
                            <div className="list-item-actions">
                                <button
                                    className="edit-button"
                                    onClick={() => handleEdit(step.idsteps)}
                                >
                                    <i className={`fas ${editingId === step.idsteps ? 'fa-save' : 'fa-pencil-alt'}`}></i>
                                </button>
                                <button
                                    className="delete-button"
                                    onClick={() => setDeleteConfirm(step)}
                                >
                                    <i className="fas fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {deleteConfirm && (
                <DeleteModal
                    isOpen={!!deleteConfirm}
                    onClose={() => setDeleteConfirm(null)}
                    onConfirm={() => handleDelete(deleteConfirm.idsteps)}
                    itemName={`step "${deleteConfirm.name}"`}
                />
            )}
        </div>
    );
} 