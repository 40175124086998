import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { debounce } from 'lodash';
import ActivitySelector from '../../components/common/ActivitySelector';
// import '../../styles/components/forms.css';
import '../../styles/dash/ExerciseOfWeek.css';

export default function ExerciseOfWeek() {
    const [activities, setActivities] = useState([]);
    const [weeklyExercises, setWeeklyExercises] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [selectedWeek, setSelectedWeek] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [copiedUuid, setCopiedUuid] = useState(null);

    useEffect(() => {
        fetchData();
        fetchWeeklyExercises();
    }, []);

    const fetchData = async () => {
        try {
            const activitiesRes = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/activities`, 
                { withCredentials: true }
            );
            setActivities(activitiesRes.data);
        } catch (error) {
            setError('Failed to fetch data');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchWeeklyExercises = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/weekly-exercises`,
                { withCredentials: true }
            );
            const sortedExercises = sortWeeklyExercises(response.data || []);
            setWeeklyExercises(sortedExercises);
        } catch (error) {
            console.error('Error fetching weekly exercises:', error);
            setWeeklyExercises({ upcoming: [], past: [] });
        }
    };

    const sortWeeklyExercises = (exercises) => {
        const currentWeek = getCurrentWeek();
        const currentYear = new Date().getFullYear();

        return {
            upcoming: exercises.filter(ex => {
                const weekYear = `${ex.year}-${ex.week}`;
                return weekYear >= `${currentYear}-${currentWeek}`;
            }).sort((a, b) => {
                if (a.year !== b.year) return a.year - b.year;
                return a.week - b.week;
            }),
            past: exercises.filter(ex => {
                const weekYear = `${ex.year}-${ex.week}`;
                return weekYear < `${currentYear}-${currentWeek}`;
            }).sort((a, b) => {
                if (a.year !== b.year) return b.year - a.year;
                return b.week - a.week;
            })
        };
    };

    const getCurrentWeek = () => {
        const now = new Date();
        const start = new Date(now.getFullYear(), 0, 1);
        const diff = now - start;
        const oneWeek = 1000 * 60 * 60 * 24 * 7;
        return Math.ceil(diff / oneWeek);
    };

    const getAvailableWeeks = () => {
        const now = new Date();
        const currentWeek = getCurrentWeek();
        const currentYear = now.getFullYear();
        const weeks = [];
        
        // Add current week and 24 weeks into the future
        for (let offset = 0; offset <= 24; offset++) {
            let targetDate = new Date(now);
            targetDate.setDate(targetDate.getDate() + (offset * 7));
            
            const targetYear = targetDate.getFullYear();
            const targetWeek = Math.ceil((targetDate - new Date(targetYear, 0, 1)) / (1000 * 60 * 60 * 24 * 7));

            weeks.push({
                value: `${targetYear}-${targetWeek}`,
                label: `Week ${targetWeek}, ${targetYear}`
            });
        }

        // Sort weeks chronologically (earliest to latest)
        return weeks.sort((a, b) => {
            const [yearA, weekA] = a.value.split('-').map(Number);
            const [yearB, weekB] = b.value.split('-').map(Number);
            
            if (yearA !== yearB) {
                return yearA - yearB; // Earlier year first
            }
            return weekA - weekB; // Earlier week first
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedActivity) {
            setError('Please select an activity');
            return;
        }
        
        const activityId = selectedActivity.idactivity || selectedActivity.idactivities;
        
        if (!activityId) {
            setError('Invalid activity selected');
            return;
        }

        try {
            const [year, week] = selectedWeek.split('-');
            console.log('Submitting activity:', {
                activityId: activityId,
                week,
                year
            });

            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/weekly-exercises`,
                {
                    activityId: parseInt(activityId),
                    week: parseInt(week),
                    year: parseInt(year)
                },
                { withCredentials: true }
            );
            setMessage('Exercise of the week set successfully');
            fetchWeeklyExercises();
            setSelectedActivity(null);
            setSelectedWeek('');
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to set exercise of the week';
            setError(errorMessage);
            console.error('Error:', error);
        }
    };

    const getShareUrl = (uuid) => {
        return `${window.location.origin}/weekly-exercise/${uuid}`;
    };

    const handleDelete = async (idweekExercise) => {
        console.log(idweekExercise, "id");
        if (window.confirm('Are you sure you want to delete this exercise?')) {
            try {
                await axios.delete(
                    `${process.env.REACT_APP_BACKEND_URL}/api/weekly-exercises/${idweekExercise}`,
                    { 
                        data: { idweekExercise },
                        withCredentials: true 
                    }
                );
                setMessage('Exercise deleted successfully');
                fetchWeeklyExercises();
            } catch (error) {
                setError('Failed to delete exercise');
                console.error('Error:', error);
            }
        }
    };

    if (loading) return <div className="loading">Loading...</div>;

    return (
        <div className="exercise-of-week-container">
            <div className="page-header">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Exercise of the Week</h1>
            </div>

            <form onSubmit={handleSubmit} className="exercise-form">
                <div className="form-group">
                    <label>Activity</label>
                    <ActivitySelector
                        activities={activities}
                        selectedActivities={selectedActivity ? [selectedActivity] : []}
                        onActivityAdd={(activity) => {
                            console.log('Selected activity:', activity);
                            if (!activity.idactivity && !activity.idactivities) {
                                console.error('Activity missing ID:', activity);
                                return;
                            }
                            setSelectedActivity(activity);
                        }}
                        onActivityRemove={() => setSelectedActivity(null)}
                        singleSelect={true}
                        searchTerm=""
                        onSearchChange={() => {}}
                    />
                </div>

                <div className="form-group">
                    <label>Week</label>
                    <select
                        value={selectedWeek}
                        onChange={(e) => setSelectedWeek(e.target.value)}
                        required
                    >
                        <option value="">Select week</option>
                        {getAvailableWeeks().map(week => (
                            <option key={week.value} value={week.value}>
                                {week.label}
                            </option>
                        ))}
                    </select>
                </div>

                <button type="submit" className="submit-button">Set Exercise of the Week</button>
            </form>

            {message && <div className="success-message">{message}</div>}
            {error && <div className="error-message">{error}</div>}

            <div className="weekly-exercises-section">
                <h2>Upcoming Exercises</h2>
                <div className="exercises-list">
                    {weeklyExercises.upcoming && weeklyExercises.upcoming.length > 0 ? (
                        weeklyExercises.upcoming.map(exercise => (
                            <div key={`${exercise.year}-${exercise.week}`} className="exercise-item">
                                <div className="exercise-info">
                                    <h3>Week {exercise.week}, {exercise.year}</h3>
                                    <p>Activity: {exercise.activityName || 'Unknown Activity'}</p>
                                    {exercise.activityDescription && (
                                        <p className="activity-description">{exercise.activityDescription}</p>
                                    )}
                                    <div className="share-link">
                                        <input 
                                            type="text" 
                                            value={getShareUrl(exercise.uuid)} 
                                            readOnly 
                                        />
                                        <CopyToClipboard 
                                            text={getShareUrl(exercise.uuid)}
                                            onCopy={() => {
                                                setCopiedUuid(exercise.uuid);
                                                setTimeout(() => setCopiedUuid(null), 2000);
                                            }}
                                        >
                                            <button className="copy-button">
                                                {copiedUuid === exercise.uuid ? (
                                                    <i className="fas fa-check"></i>
                                                ) : (
                                                    <i className="fas fa-copy"></i>
                                                )}
                                            </button>
                                        </CopyToClipboard>
                                    </div>
                                </div>

                                <button
                                    onClick={() => handleDelete(exercise.idweekExercise)}
                                    className="delete-button"
                                >
                                    <i className="fas fa-trash"></i>
                                </button>
                            </div>
                        ))
                    ) : (
                        <div className="no-exercises">
                            <p>No upcoming exercises scheduled</p>
                        </div>
                    )}
                </div>

                <h2>Past Exercises</h2>
                <div className="exercises-list past">
                    {weeklyExercises.past && weeklyExercises.past.length > 0 ? (
                        weeklyExercises.past.map(exercise => (
                            <div key={`${exercise.year}-${exercise.week}`} className="exercise-item">
                                <div className="exercise-info">
                                    <h3>Week {exercise.week}, {exercise.year}</h3>
                                    <p>Activity: {exercise.activityName}</p>
                                    <div className="share-link">
                                        <input 
                                            type="text" 
                                            value={getShareUrl(exercise.uuid)} 
                                            readOnly 
                                        />
                                        <CopyToClipboard 
                                            text={getShareUrl(exercise.uuid)}
                                            onCopy={() => {
                                                setCopiedUuid(exercise.uuid);
                                                setTimeout(() => setCopiedUuid(null), 2000);
                                            }}
                                        >
                                            <button className="copy-button">
                                                {copiedUuid === exercise.uuid ? (
                                                    <i className="fas fa-check"></i>
                                                ) : (
                                                    <i className="fas fa-copy"></i>
                                                )}
                                            </button>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="no-exercises">
                            <p>No past exercises found</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
} 