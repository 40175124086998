import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function ActivitiesList() {
    const [activities, setActivities] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchActivities();
    }, []);

    const fetchActivities = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/activities`,
                { withCredentials: true }
            );
            setActivities(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching activities:', error);
            setError('Failed to load activities');
            setLoading(false);
        }
    };

    const handleActivityClick = (activityId) => {
        navigate(`/activities/${activityId}`);
    };

    if (loading) return <div className="loading">Loading...</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>All Activities</h1>
            </div>

            {error && <div className="error-message">{error}</div>}

            <div className="list-container">
                {activities.length > 0 ? (
                    activities.map(activity => (
                        <div 
                            key={activity.idactivity} 
                            className="list-item clickable"
                            onClick={() => handleActivityClick(activity.idactivity)}
                        >
                            <div className="user-info">
                                <h3>{activity.name}</h3>
                                {activity.description && (
                                    <p className="user-email">{activity.description}</p>
                                )}
                                {activity.programName && (
                                    <p className="user-role">Program: {activity.programName}</p>
                                )}
                                {activity.businessName && (
                                    <p className="user-business">Business: {activity.businessName}</p>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-items-message">No activities found</p>
                )}
            </div>
        </div>
    );
} 