import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function PlaylistsList() {
    const [playlists, setPlaylists] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchPlaylists();
    }, []);

    const fetchPlaylists = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/playlists`,
                { withCredentials: true }
            );
            setPlaylists(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching playlists:', error);
            setError('Failed to load playlists');
            setLoading(false);
        }
    };

    const handlePlaylistClick = (playlistId) => {
        navigate(`/playlists/${playlistId}`);
    };

    if (loading) return <div className="loading">Loading...</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>All Playlists</h1>
            </div>

            {error && <div className="error-message">{error}</div>}

            <div className="list-container">
                {playlists.length > 0 ? (
                    playlists.map(playlist => (
                        <div 
                            key={playlist.idplaylist} 
                            className="list-item clickable"
                            onClick={() => handlePlaylistClick(playlist.idplaylist)}
                        >
                            <div className="user-info">
                                <h3>{playlist.name}</h3>
                                {playlist.activityName && (
                                    <p className="user-role">Activity: {playlist.activityName}</p>
                                )}
                                {playlist.programName && (
                                    <p className="user-role">Program: {playlist.programName}</p>
                                )}
                                {playlist.duration && (
                                    <p className="user-role">Duration: {playlist.duration} minutes</p>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-items-message">No playlists found</p>
                )}
            </div>
        </div>
    );
} 