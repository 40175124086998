import { useState } from 'react';
import axios from 'axios';
import '../../styles/components/forms.css';

export default function ProfileSubmission() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        imageUrl: ''
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const form = new FormData();
            form.append('name', formData.name);
            form.append('email', formData.email);
            form.append('phone', formData.phone);
            
            if (selectedFile) {
                form.append('media', selectedFile);
            }
            
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/newapp`,
                form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            setMessage('Thank you for submitting your profile! You have been subscribed to our newsletter.');
            setFormData({
                name: '',
                email: '',
                phone: '',
                imageUrl: ''
            });
            setSelectedFile(null);
        } catch (error) {
            setError('Failed to submit profile. Please try again.');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="profile-submission-container">
            <h1>Join Our Community</h1>
            <p className="intro-text">
                Join our fitness community and stay updated with our latest content.
            </p>

            <form onSubmit={handleSubmit} className="profile-form">
                <div className="form-group">
                    <label htmlFor="name">Full Name *</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email Address *</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </div>

                {/* <div className="file-upload">
                    <label className="upload-button">
                        <i className="fas fa-cloud-upload-alt"></i>
                        Upload Video
                        <input
                            type="file"
                            accept="video/*"
                            onChange={handleFileSelect}
                            style={{ display: 'none' }}
                            multiple
                        />
                    </label>
                    {files.map((file, index) => (
                        <div key={index} className="selected-file">
                            <span>{file.name}</span>
                            <button
                                type="button"
                                className="remove-file"
                                onClick={() => removeFile(index)}
                            >
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                    ))}
                </div> */}

                {/* <div className="form-group"> */}
                    <label>Last opp bilde av nåværende app abonnement</label>
                    <div className="file-upload">
                        <label className="upload-button">
                            <i className="fas fa-cloud-upload-alt"></i>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                className="file-input"
                                style={{ display: 'none' }}
                            />
                            {selectedFile ? 'Change Image' : 'Upload Image'}
                        </label>
                        {selectedFile && (
                            <div className="selected-file">
                                <span>{selectedFile.name}</span>
                                <button 
                                    type="button" 
                                    className="remove-file"
                                    onClick={() => setSelectedFile(null)}
                                >
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                        )}
                    </div>
                {/* </div> */}

                <button 
                    type="submit" 
                    className="submit-button"
                    disabled={loading}
                >
                    {loading ? 'Submitting...' : 'Submit Profile'}
                </button>
            </form>

            {message && <div className="success-message">{message}</div>}
            {error && <div className="error-message">{error}</div>}
        </div>
    );
} 