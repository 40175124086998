import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';

export default function BusinessList() {
    const [businesses, setBusinesses] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchBusinesses();
    }, []);

    const fetchBusinesses = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/businesses`,
                { withCredentials: true }
            );
          
            setBusinesses(response.data);
        } catch (error) {
            console.error('Error fetching businesses:', error);
            setError('Failed to load businesses');
        } finally {
            setLoading(false);
        }
    };

    const handleBusinessClick = (businessId) => {
        navigate(`/businesses/${businessId}`);
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>All Businesses</h1>
            </div>

            <div className="list-container">
                {businesses.map((business) => (
                    <div 
                        key={business.idbusiness} 
                        className="list-item clickable"
                        onClick={() => handleBusinessClick(business.idbusiness)}
                    >
                        <div className="business-info">
                            <h3>{business.name}</h3>
                            <p>Code: {business.code}</p>
                            <p>Seats Available: {business.seats_available}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
} 